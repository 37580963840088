// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Header = "L_b5";
export var isCompact = "L_b6";
export var isActive = "L_b7";
export var isInverse = "L_b8";
export var bg = "L_M";
export var isHidden = "L_b9";
export var grid = "L_bc";
export var btnMenu = "L_cb";
export var logo = "L_bP";
export var logo__inner = "L_cc";
export var logo__comptact = "L_cd";
export var aniSpin = "L_l";