import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { AnimatePresence, motion } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import { registerState } from '@/store/registerState'
import { getBtnColors } from '@/utils/getBtnColors'
import { Container } from '@/components/Container'
import { Btn } from '@/components/Btn'
import { Heading } from '@/components/Heading'
import { MailchimpForm } from '@/components/MailchimpForm'

import variants from './RegisterPopup.motion'
import * as styles from './RegisterPopup.styles.scss'

const RegisterPopup = ({ url, fields, successTitle, successDesc }) => {
  const { device, viewportHeight } = useRecoilValue(browserState)
  const [isRegisterOpen, setIsRegisterOpen] = useRecoilState(registerState)
  const handleClickClose = () => setIsRegisterOpen(false)
  const elementRef = useRef()

  useEffect(() => {
    if (isRegisterOpen) {
      disableBodyScroll(elementRef.current, { reserveScrollBarGap: true })
    } else {
      enableBodyScroll(elementRef.current)
    }
  }, [isRegisterOpen])

  return (
    <div ref={elementRef}>
      <AnimatePresence>
        {isRegisterOpen && (
          <motion.div
            className={styles.RegisterPopup}
            key="nav"
            initial="initial"
            animate="visible"
            exit="exit"
            style={{
              height: device === 'mobile' ? `${viewportHeight}px` : undefined,
            }}
          >
            <div
              className={styles.scrollable}
              style={{ overflowY: isRegisterOpen ? 'auto' : 'hidden' }}
            >
              <div className={styles.scrollable__inner}>
                <motion.button
                  type="button"
                  onClick={handleClickClose}
                  className={styles.overlay}
                  variants={variants.overlay}
                />
                <div>
                  <Container>
                    <div className={styles.panel}>
                      <motion.div
                        className={styles.bg}
                        variants={variants.bg}
                      />
                      <motion.div
                        variants={variants.inner}
                        className={styles.inner}
                      >
                        <button
                          type="button"
                          onClick={handleClickClose}
                          className={styles.close}
                        >
                          Close
                        </button>
                        <MailchimpForm
                          url={url}
                          fields={fields}
                          success={
                            <div>
                              <Heading type="h2">{successTitle}</Heading>
                              <div className={styles.success__description}>
                                {successDesc}
                              </div>
                              <Btn
                                onClick={handleClickClose}
                                theme={getBtnColors('green')}
                              >
                                Close
                              </Btn>
                            </div>
                          }
                        />
                      </motion.div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

RegisterPopup.defaultProps = {
  fields: [],
}

RegisterPopup.propTypes = {
  url: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  successTitle: PropTypes.node,
  successDesc: PropTypes.node,
}

export { RegisterPopup }
