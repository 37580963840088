import { atom } from 'recoil'

/**
 * Store menu state here
 *
 * @usage
 * const [isRegisterOpen, setIsRegisterOpen] = useRecoilValue(registerState)
 */

export const registerState = atom({
  key: 'registerState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
