/**
 * Link Resolver for Prismic content
 *
 * @param {object} page
 */

exports.linkResolver = (page) => {
  if (page) {
    if (page.uid === 'home') return `/`
    if (page.type === 'blog_post') return `/news/${page.uid}`
    if (page.type === 'faq') return `/faq/${page.uid}`

    // fallback route
    return `/${page.uid}`
  }
  return null
}
