import React from 'react'
import PropTypes from 'prop-types'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import Layout from '../../layout'
import { useSiteConfig } from '../hooks/useSiteConfig'
import 'gatsby-plugin-prismic-previews/dist/styles.css'

/**
 * Prismic Layout is a pre-wrap which pulls in any
 * prismic content to pass to the main Layout component
 */

const LayoutPrismic = ({ path, pageContext, children, location }) => {
  const site = useSiteConfig()

  const page = {
    title: pageContext.title || '',
    path,
    image: pageContext.image,
    bgColor: pageContext.bgColor,
    hasHero: pageContext.hasHero,
  }

  return (
    <PrismicPreviewProvider>
      <Layout site={site} page={page} location={location}>
        {children}
      </Layout>
    </PrismicPreviewProvider>
  )
}

LayoutPrismic.defaultProps = {
  children: undefined,
  path: '',
  pageContext: {},
}

LayoutPrismic.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  pageContext: PropTypes.object,
  location: PropTypes.object.isRequired,
}

export default LayoutPrismic
