// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Footer = "P_cq";
export var scroll = "P_bL";
export var fixed = "P_cr";
export var isHidden = "P_b9";
export var inner = "P_bv";
export var info = "P_cs";
export var nav = "P_cg";
export var nav__list = "P_ct";
export var logo = "P_bP";
export var logo__inner = "P_cc";
export var copyright = "P_cv";
export var aniSpin = "P_l";