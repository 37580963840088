/**
 * convert string to slug
 * @param {String} text
 * @param {Object} options
 */

const colors = {
  gold: '#f6ce7f',
  white: '#ece9e4',
  black: '#323030',
  green: '#085a4b',
}

export const getBtnColors = (bgColor) => {
  switch (bgColor) {
    case 'custom': {
      return {
        bg: colors.gold,
        hoverBg: colors.white,
        hoverText: colors.black,
        text: colors.black,
      }
    }
    case 'green': {
      return {
        bg: colors.gold,
        hoverBg: colors.white,
        hoverText: colors.black,
        text: colors.black,
      }
    }
    case 'gold': {
      return {
        bg: colors.green,
        hoverBg: colors.black,
        hoverText: colors.gold,
        text: colors.gold,
      }
    }
    case 'white': {
      return {
        bg: colors.gold,
        hoverBg: colors.green,
        hoverText: colors.white,
        text: colors.black,
      }
    }
    case 'black': {
      return {
        bg: colors.white,
        hoverBg: colors.gold,
        hoverText: colors.black,
        text: colors.black,
      }
    }
    default: {
      return {
        bg: colors.green,
        hoverBg: colors.black,
        hoverText: colors.white,
        text: colors.white,
      }
    }
  }
}
