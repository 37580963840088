const variants = {
  icon: {
    hidden: {
      opacity: 0,
      y: '100%',
    },
    visible: {
      opacity: 1,
      y: '0%',
      transition: {
        delay: 1.5,
        duration: 0.75,
        ease: [0.645, 0.045, 0.355, 1],
      },
    },
  },
  text: {
    hidden: {
      opacity: 0,
      y: '-100%',
    },
    visible: ({ delay }) => ({
      opacity: 1,
      y: '0%',
      transition: {
        delay: 1.65 + delay,
        duration: 0.75,
        ease: [0.645, 0.045, 0.355, 1],
      },
    }),
  },
}

export default variants
