import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

import { Link } from '@/components/TransitionLink'
import { Logo } from '@/components/Logo'
import { Container } from '@/components/Container'

import * as styles from './Footer.styles.scss'

const Footer = ({ nav, id, info, copyright }) => {
  const { ref, inView } = useInView()

  return (
    <footer className={styles.Footer} id={id}>
      <div className={styles.scroll} ref={ref} />
      <div className={classnames(styles.fixed, { [styles.isHidden]: !inView })}>
        <Container>
          <div className={styles.inner}>
            <div className={styles.logo}>
              <div className={styles.logo__inner}>
                <Link to="/">
                  <Logo isInverse />
                </Link>
              </div>
            </div>
            <div className={styles.nav}>
              {nav.map((list) => {
                return (
                  <ul className={styles.nav__list} key={list.id}>
                    {list.items.map((item) => {
                      return (
                        <li key={item.id}>
                          {item.url && (
                            <Link to={item.url} target={item.target}>
                              {item.title}
                            </Link>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                )
              })}
            </div>
            <div className={styles.info}>
              <div>{info}</div>
              <div className={styles.copyright}>
                &copy;{new Date().getFullYear()}
                {copyright && (
                  <>
                    {` `}
                    {copyright}
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  nav: [],
  id: 'footer',
  info: undefined,
  copyright: undefined,
}

Footer.propTypes = {
  nav: PropTypes.array,
  id: PropTypes.string,
  info: PropTypes.node,
  copyright: PropTypes.node,
}

export { Footer }
