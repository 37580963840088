// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Btn = "W_cV";
export var inner = "W_bv";
export var hover = "W_cW";
export var wrapper = "W_cX";
export var text = "W_bZ";
export var bg = "W_M";
export var aniSpin = "W_l";