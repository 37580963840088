import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import { menuState } from '@/store/menuState'

import { Link } from '@/components/TransitionLink'
import { Container } from '@/components/Container'

import variants from './Menu.motion'
import * as styles from './Menu.styles.scss'

const Menu = ({ nav }) => {
  const { device, viewportHeight } = useRecoilValue(browserState)
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const handleClickLink = () => setIsMenuOpen(false)

  return (
    <div id="menu">
      <AnimatePresence>
        {isMenuOpen && (
          <motion.nav
            className={styles.Menu}
            key="nav"
            initial="initial"
            animate="visible"
            exit="exit"
            style={{
              minHeight:
                device === 'mobile' ? `${viewportHeight}px` : undefined,
            }}
          >
            <motion.div className={styles.bg} variants={variants.bg} />
            <motion.div className={styles.nav} variants={variants.nav}>
              <Container className={styles.container}>
                {nav.map((list) => {
                  return (
                    <ul key={list.id}>
                      {list.items.map((item) => {
                        return (
                          <li key={item.id} className={styles.item}>
                            {item.url && (
                              <Link
                                to={item.url}
                                target={item.target}
                                className={styles.link}
                                onClick={handleClickLink}
                              >
                                {item.title}
                              </Link>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  )
                })}
              </Container>
            </motion.div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  )
}

Menu.defaultProps = {
  nav: [],
}

Menu.propTypes = {
  nav: PropTypes.array,
}

export { Menu }
