import React from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import * as styles from './StickyHeader.styles.scss'

const StickyHeader = ({ children }) => {
  const { windowHeight } = useRecoilValue(browserState)

  return (
    <Headroom
      pinStart={windowHeight || 1000}
      disableInlineStyles
      className={styles.StickyHeader}
      wrapperStyle={{ maxHeight: '0px' }}
    >
      {children}
    </Headroom>
  )
}

StickyHeader.propTypes = {
  children: PropTypes.node,
}

export { StickyHeader }
