import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Container.styles.scss'

const Container = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Container }
