import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import TransitionService from '@/services/TransitionService'
import { transitionState } from '@/store/transitionState'

/**
 * React connected component for the TransitionService
 */

const TransitionServiceComponent = () => {
  const setTransition = useSetRecoilState(transitionState)

  useEffect(() => {
    const onTransition = (state) => setTransition(state)

    TransitionService.on(TransitionService.events.TRANSITION, onTransition)

    return () => {
      TransitionService.off(TransitionService.events.TRANSITION, onTransition)
    }
  }, [setTransition])

  return <></>
}

export { TransitionServiceComponent }
