// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-icons-jsx": () => import("./../../../src/pages/icons.jsx" /* webpackChunkName: "component---src-pages-icons-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-prismic-types-blog-post-prismic-jsx": () => import("./../../../src/prismic/types/BlogPost.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-blog-post-prismic-jsx" */),
  "component---src-prismic-types-faq-prismic-jsx": () => import("./../../../src/prismic/types/Faq.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-faq-prismic-jsx" */),
  "component---src-prismic-types-page-prismic-jsx": () => import("./../../../src/prismic/types/Page.prismic.jsx" /* webpackChunkName: "component---src-prismic-types-page-prismic-jsx" */)
}

