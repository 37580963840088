import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '../utils/getLink'
import { getSlug } from '@/utils/getSlug'
import { getRichText } from '@/prismic/utils/getRichText'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        prismicConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            nav_primary {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            nav_secondary {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            nav_tertiary {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            footer_info {
              raw
            }
            footer_copyright {
              raw
            }
            social_links {
              title
              type
              url
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const prismicConfig = data.prismicConfig.data || {}

  const navPrimary = []
  if (prismicConfig.nav_primary) {
    prismicConfig.nav_primary.forEach((item, index) => {
      navPrimary.push(getLink(item.link, item.title, `nav_primary_${index}`))
    })
  }

  const navSecondary = []
  if (prismicConfig.nav_secondary) {
    prismicConfig.nav_secondary.forEach((item, index) => {
      navSecondary.push(
        getLink(item.link, item.title, `nav_secondary_${index}`)
      )
    })
  }

  const navTertiary = []
  if (prismicConfig.nav_tertiary) {
    prismicConfig.nav_tertiary.forEach((item, index) => {
      navTertiary.push(getLink(item.link, item.title, `nav_tertiary_${index}`))
    })
  }

  const nav = [
    { id: 'nav-01', items: navPrimary },
    { id: 'nav-02', items: navSecondary },
    { id: 'nav-03', items: navTertiary },
  ]

  const socialLinks = []
  if (prismicConfig.social_links) {
    prismicConfig.social_links.forEach((item, index) => {
      socialLinks.push({
        id: `social_link_${index}`,
        title: item.title || item.type,
        type: getSlug(item.type),
        url: item.url,
      })
    })
  }

  const title = prismicConfig.title || gatsbyConfig.title

  return {
    metadata: {
      title,
      description: prismicConfig.meta_description || gatsbyConfig.description,
      subtitle: prismicConfig.meta_subtitle || gatsbyConfig.subtitle,
      image: get(prismicConfig, 'meta_image.url') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    header: {
      title,
    },
    menu: {
      nav,
    },
    register: {
      url: 'https://maitahivillate.us5.list-manage.com/subscribe/post?u=52e33b7797aad5a3fc1ab298a&amp;id=66ec09da9b',
      successTitle: 'Thank you',
      successDesc: <p>We’ll be in touch shortly with news and updates.</p>,
      fields: [
        {
          type: 'radio',
          name: 'SUPPROJECT',
          id: 'mce-SUPPROJECT',
          label: 'I’m in support of this project:',
          isRequired: true,
          options: [
            {
              id: 'mce-SUPPROJECT-0',
              title: 'Yes',
              value: 'Yes',
            },
            {
              id: 'mce-SUPPROJECT-1',
              title: 'No',
              value: 'No',
            },
            {
              id: 'mce-SUPPROJECT-2',
              title: 'Undecided',
              value: 'Undecided',
            },
          ],
        },
        {
          type: 'radio',
          name: 'INT_GROUP',
          id: 'mce-INT_GROUP',
          label: 'I’d like to sign-up for further information as a:',
          isRequired: true,
          options: [
            {
              id: 'mce-INT_GROUP-0',
              title: 'Home Buyer',
              value: 'Home Buyer',
            },
            {
              id: 'mce-INT_GROUP-1',
              title: 'Neighbour Of The Project',
              value: 'Neighbour Of The Project',
            },
            {
              id: 'mce-INT_GROUP-2',
              title: 'Nelson Tasman Resident',
              value: 'Nelson Tasman Resident',
            },
            {
              id: 'mce-INT_GROUP-3',
              title: 'Recreational User Of The Area',
              value: 'Recreational User Of The Area',
            },
            {
              id: 'mce-INT_GROUP-4',
              title: 'Unspecified',
              value: 'Unspecified',
            },
          ],
        },
        {
          type: 'email',
          label: 'Your email',
          id: 'mce-EMAIL',
          name: 'EMAIL',
          isRequired: true,
        },
      ],
    },
    footer: {
      nav,
      socialLinks,
      info: getRichText(prismicConfig, 'footer_info.raw'),
      copyright: getRichText(prismicConfig, 'footer_copyright.raw', 'p'),
    },
  }
}
