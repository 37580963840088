import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { globalHistory } from '@reach/router'
import TransitionService from '@/services/TransitionService'
import { navigate } from 'gatsby'

const TransitionLink = ({ children, onClick, to, ...props }) => {
  const isInternalPath = to ? /^\/(?!\/)/.test(to) : false

  if (isInternalPath) {
    return (
      <Link
        to={to}
        onClick={(event) => {
          event.preventDefault()

          if (typeof onClick === 'function') onClick()

          const currentPath = globalHistory.location.pathname
          if (TransitionService.ready && currentPath !== to) {
            TransitionService.in(to)
              .then(() => {
                navigate(to)
              })
              .catch((error) => console.warn(error))
          }
        }}
        {...props}
      >
        {children}
      </Link>
    )
  }
  return (
    <Link onClick={onClick} to={to} {...props}>
      {children}
    </Link>
  )
}

TransitionLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export { TransitionLink }
