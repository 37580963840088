import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import classnames from 'classnames'
import variants from './BtnMenu.motion'
import * as styles from './BtnMenu.styles.scss'

const BtnMenu = ({ className, onClick, isActive }) => {
  const [isHovering, setHovering] = useState(false)

  return (
    <motion.button
      type="button"
      className={classnames(styles.BtnMenu, className)}
      onClick={onClick}
      initial="hidden"
      animate={
        isHovering
          ? isActive
            ? 'activeHover'
            : 'initialHover'
          : isActive
          ? 'active'
          : 'initial'
      }
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      variants={variants.BtnMenu}
      aria-label={`${isActive ? 'Close' : 'Open'} Menu`}
      aria-expanded={isActive}
      aria-controls="menu" // references the menu id attribute
    >
      <motion.div className={styles.line1} variants={variants.line1} />
      <motion.div className={styles.line2} variants={variants.line2} />
    </motion.button>
  )
}

BtnMenu.defaultProps = {
  onClick: () => {},
  isActive: false,
}

BtnMenu.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

export { BtnMenu }
