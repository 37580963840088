const easeOutQuart = [0.25, 1, 0.5, 1]

const variants = {
  inner: {
    initial: ({ bg }) => ({
      backgroundColor: bg,
    }),
  },
  bg: {
    initial: ({ bg }) => ({
      y: '75%',
      backgroundColor: bg,
      transition: { duration: 0 },
    }),
    mouseOver: ({ hoverBg }) => ({
      y: '0%',
      backgroundColor: hoverBg,
      transition: { duration: 0.35, ease: easeOutQuart },
    }),
    mouseLeave: ({ bg }) => ({
      y: '-75%',
      backgroundColor: bg,
      transition: { duration: 0.35, ease: easeOutQuart },
    }),
  },
  text: {
    initial: ({ text }) => ({
      opacity: 1,
      color: text,
      y: '0%',
      transition: { duration: 0.2, ease: easeOutQuart },
    }),
    mouseOver: ({ hoverText }) => ({
      opacity: [0, 1],
      y: ['30%', '0%'],
      color: hoverText,
      transition: { duration: 0.35, ease: easeOutQuart },
    }),
    mouseLeave: ({ text }) => ({
      opacity: 1,
      y: '-10%',
      color: text,
      transition: { duration: 0.35, ease: easeOutQuart },
    }),
  },
}
export default variants
