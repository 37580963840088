const variants = {
  line1: {
    hidden: {
      scaleX: 0,
    },
    initial: {
      scaleX: 1.01,
    },
    initialHover: {
      scaleX: 1.2,
    },
    active: {
      scaleX: 1,
    },
    activeHover: {
      scaleX: 1.1,
    },
  },
  line2: {
    hidden: {
      scaleX: 0,
    },
    initial: {
      scaleX: 1.01,
      z: 0,
    },
    initialHover: {
      scaleX: 1.2,
    },
    active: {
      scaleX: 0,
    },
    activeHover: {
      scaleX: 0.33,
    },
  },
}
export default variants
