import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHover } from 'react-use-gesture'
import { Link } from '@/components/TransitionLink'
import { getBtnColors } from '@/utils/getBtnColors'
import variants from './Btn.motion'
import * as styles from './Btn.styles.scss'

const Btn = ({ children, href, onClick, target, type, theme }) => {
  const [hoverState, setHoverState] = useState('initial')
  const defaultColors = getBtnColors()
  const themeColors = { ...defaultColors, ...theme }

  const handleHover = (event) => {
    setHoverState(event.hovering ? 'mouseOver' : 'mouseLeave')
  }

  const handleAnimationComplete = () => {
    if (hoverState === 'mouseLeave') {
      setHoverState('initial')
    }
  }

  const bindHover = useHover((state) => handleHover(state), {})

  const renderInner = (
    <>
      <span className={styles.hover} {...bindHover()} />
      <motion.span
        className={styles.inner}
        initial="initial"
        animate={hoverState}
        onAnimationComplete={handleAnimationComplete}
        variants={variants.inner}
        custom={themeColors}
      >
        <motion.span
          className={styles.bg}
          variants={variants.bg}
          custom={themeColors}
        />
        <motion.span
          className={styles.wrapper}
          variants={variants.text}
          custom={themeColors}
        >
          {children && <span className={styles.text}>{children}</span>}
        </motion.span>
      </motion.span>
    </>
  )

  // if href then return a link
  if (href) {
    return (
      <Link to={href} target={target} className={styles.Btn} onClick={onClick}>
        {renderInner}
      </Link>
    )
  }

  // default to a button element
  return (
    <button
      type={type} // eslint-disable-line
      className={styles.Btn}
      onClick={onClick}
    >
      {renderInner}
    </button>
  )
}

Btn.defaultProps = {
  onClick: () => {},
  type: 'button',
}

Btn.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.object,
}

export { Btn }
