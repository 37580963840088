import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import { menuState } from '@/store/menuState'

import { Link } from '@/components/TransitionLink'
import { Logo } from '@/components/Logo'
import { BtnMenu } from '@/components/BtnMenu'
import { Container } from '@/components/Container'

import * as styles from './Header.styles.scss'

const Header = ({ title, isCompact, isInverse, isTransparent }) => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const [isActive, setIsActive] = useState(false)

  const handleToggleNav = () => {
    setIsActive(!isMenuOpen)
    setIsMenuOpen(!isMenuOpen)
  }
  const handleClickLink = () => setIsMenuOpen(false)

  useEffect(() => {
    if (!isMenuOpen && isActive) {
      setIsActive(false)
    }
  }, [isMenuOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header
      className={classnames(styles.Header, {
        [styles.isCompact]: !!isCompact,
        [styles.isActive]: !!isActive,
        [styles.isInverse]: !!isInverse,
      })}
    >
      <div
        className={classnames(styles.bg, {
          [styles.isHidden]: isTransparent || isMenuOpen,
        })}
      />
      <Container>
        <div className={styles.grid}>
          <h1 className={styles.logo}>
            <div className={styles.logo__inner}>
              <Link to="/" aria-label={title} onClick={handleClickLink}>
                {isCompact ? (
                  <Logo
                    hasText={false}
                    isInverse={isInverse}
                    className={styles.logo__comptact}
                  />
                ) : (
                  <Logo isInverse={isInverse || isMenuOpen} />
                )}
              </Link>
            </div>
          </h1>
          <div className={styles.btnMenu}>
            <BtnMenu onClick={handleToggleNav} isActive={isMenuOpen} />
          </div>
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  title: undefined,
  isCompact: false,
  isInverse: false,
  isTransparent: false,
}

Header.propTypes = {
  title: PropTypes.string,
  isCompact: PropTypes.bool,
  isInverse: PropTypes.bool,
  isTransparent: PropTypes.bool,
}

export { Header }
