const easeIn = [0.645, 0.045, 0.355, 1]
const easeOut = [1, 0.355, 0.045, 1]

const variants = {
  bg: {
    initial: {
      x: 0,
      y: 100,
      opacity: 0,
    },
    visible: {
      x: 0,
      y: 1,
      opacity: 1,
      transition: {
        ease: easeIn,
        duration: 0.4,
        delay: 0.3,
      },
    },
    exit: {
      x: 0,
      y: -100,
      opacity: 0,
      transition: {
        ease: easeOut,
        duration: 0.4,
        delay: 0,
      },
    },
  },
  overlay: {
    initial: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 0.2,
      },
    },
  },
  inner: {
    initial: {
      y: 30,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: easeIn,
        duration: 0.4,
        delay: 0.3,
      },
    },
    exit: {
      y: -30,
      opacity: 0,
      transition: {
        duration: 0.4,
        delay: 0,
        ease: easeOut,
      },
    },
  },
}

export default variants
