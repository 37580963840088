// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Layout = "b_b";
export var Layout__loader = "b_c";
export var Layout__main = "b_d";
export var Layout__hasHero = "b_f";
export var Layout__header = "b_g";
export var Layout__blackBgColor = "b_h";
export var Layout__greenBgColor = "b_j";
export var Layout__whiteBgColor = "b_k";
export var aniSpin = "b_l";