const ease = [0.645, 0.045, 0.355, 1]

const variants = {
  bg: {
    initial: {
      y: '-100%',
    },
    visible: {
      y: '0%',
      transition: {
        ease: ease,
      },
    },
    exit: {
      y: '100%',
      transition: {
        ease: ease,
        delay: 0.1,
      },
    },
  },
  nav: {
    initial: {
      y: '-50%',
      opacity: 0,
    },
    visible: {
      y: '0%',
      opacity: 1,
      transition: {
        ease: ease,
        delay: 0.1,
      },
    },
    exit: {
      y: '100%',
      opacity: 0,
      transition: {
        ease: ease,
      },
    },
  },
}

export default variants
