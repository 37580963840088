import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import classnames from 'classnames'

import variants from './Logo.motion'
import * as styles from './Logo.styles.scss'
import Icon from './images/icon.inline.svg'
import Maitahi from './images/text-maitahi.inline.svg'
import Village from './images/text-village.inline.svg'
//import Bayview from './images/text-bayview.inline.svg'

const Logo = ({ className, hasText, isInverse }) => {
  return (
    <motion.div
      className={classnames(styles.Logo, className, {
        [styles.isInverse]: isInverse,
      })}
      initial="hidden"
      animate="visible"
    >
      <div className={styles.mask}>
        <motion.div variants={variants.icon} className={styles.icon}>
          <Icon />
        </motion.div>
      </div>
      {hasText && (
        <div className={styles.textWrapper} aria-label="Maitahi Village">
          <div className={styles.mask}>
            <motion.div
              className={styles.text}
              variants={variants.text}
              custom={{ delay: 0 }}
            >
              <Maitahi />
            </motion.div>
          </div>
          <div className={styles.mask}>
            <motion.div
              className={styles.text}
              variants={variants.text}
              custom={{ delay: 0.15 }}
            >
              <Village />
            </motion.div>
          </div>
        </div>
      )}
    </motion.div>
  )
}

Logo.defaultProps = {
  className: undefined,
  hasText: true,
  isInverse: false,
}

Logo.propTypes = {
  className: PropTypes.string,
  hasText: PropTypes.bool,
  isInverse: PropTypes.bool,
}

export { Logo }
