import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { RecoilRoot } from 'recoil'

import { TransitionServiceComponent } from '@/services/TransitionServiceComponent'
import { ResizeService } from '@/services/ResizeService'
import { Metadata } from '@/components/Metadata'
import { Header } from '@/components/Header'
import { Menu } from '@/components/Menu'
import { StickyHeader } from '@/components/StickyHeader'
import { FontFaceLoader } from '@/components/FontFaceLoader'
import { Footer } from '@/components/Footer'
import { PageTransition } from '@/components/PageTransition'
import { RegisterPopup } from '@/components/RegisterPopup'
import favicon from '@/assets/favicon.png'
import faviconDarkmode from '@/assets/favicon-darkmode.png'

import '@/styles/main.scss'
import * as styles from './Layout.styles.scss'

const Layout = ({ site, page, children }) => {
  return (
    <div>
      <RecoilRoot>
        <Metadata
          title={page.title}
          defaultTitle={
            site.metadata.subtitle
              ? `${site.metadata.title}: ${site.metadata.subtitle}`
              : site.metadata.title
          }
          titleTemplate={`%s — ${site.metadata.title}`}
          favicon={{ default: favicon, darkMode: faviconDarkmode }}
          description={site.metadata.description}
          image={page.image || site.metadata.image}
          siteUrl={site.metadata.siteUrl}
          path={page.path}
        />
        <FontFaceLoader
          fonts={['PolySans-Neutral', 'PolySans-Median']}
          renderLoader={
            <div
              className={classnames(styles.Layout__loader, {
                [styles[`Layout__${page.bgColor}BgColor`]]: !!page.bgColor,
              })}
            />
          }
        >
          <ResizeService>
            <div>
              <div
                className={classnames(styles.Layout, {
                  [styles.Layout__hasHero]: page.hasHero,
                  [styles[`Layout__${page.bgColor}BgColor`]]: !!page.bgColor,
                })}
              >
                <StickyHeader>
                  <Header {...site.header} isCompact isInverse />
                </StickyHeader>
                <div className={styles.Layout__header}>
                  <Header
                    {...site.header}
                    isInverse={['green', 'black'].includes(page.bgColor)}
                    isTransparent
                  />
                </div>
                <Menu {...site.menu} />
                <RegisterPopup {...site.register} />
                <main className={styles.Layout__main}>{children}</main>
                <Footer {...site.footer} />
              </div>
            </div>
          </ResizeService>
        </FontFaceLoader>
        <TransitionServiceComponent />
        <PageTransition />
      </RecoilRoot>
    </div>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    favicon: undefined,
    header: {},
    footer: {},
  },
  page: {
    bgColor: 'white',
    hasHero: false,
  },
}

Layout.propTypes = {
  site: PropTypes.shape({
    metadata: PropTypes.object,
    favicon: PropTypes.string,
    header: PropTypes.object,
    footer: PropTypes.object,
  }),
  page: PropTypes.shape({
    bgColor: PropTypes.string,
    hasHero: PropTypes.bool,
    title: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

export default Layout
