import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './RadioInput.styles.scss'

const RadioInput = ({ options, label, name, onChange, value }) => (
  <div className={styles.RadioInput}>
    <div className={styles.label}>{label}</div>
    <ul>
      {options.map((option) => (
        <li key={option.id} className={styles.option}>
          <label htmlFor={option.id} className={styles.labelWrap}>
            <span className={styles.inputWrap}>
              <input
                type="radio"
                value={option.value}
                name={name}
                id={option.id}
                onChange={onChange}
                className={styles.input}
                checked={value === option.value}
              />
              <span className={styles.control} />
            </span>
            {option.title}
          </label>
        </li>
      ))}
    </ul>
  </div>
)

RadioInput.propTypes = {
  children: PropTypes.node,
}

export { RadioInput }
