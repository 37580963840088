import React, { useEffect, useCallback, useRef } from 'react'
import classnames from 'classnames'
import { gsap } from 'gsap'
import { useRecoilValue } from 'recoil'
import { transitionState } from '@/store/transitionState'
import * as styles from './PageTransition.styles.scss'

import Shape01 from './images/shape-01.inline.svg'
import Shape02 from './images/shape-02.inline.svg'
import Shape03 from './images/shape-03.inline.svg'
import Shape04 from './images/shape-04.inline.svg'

const DEBUG = false

const PageTransition = () => {
  const rootRef = useRef()
  const bgRef = useRef()
  const darkenRef = useRef()
  const shapesRef = useRef()
  const transition = useRecoilValue(transitionState)

  const playEnterTransition = useCallback(() => {
    const shapes = shapesRef.current.querySelectorAll('.ani-shape')

    const tl = gsap.timeline()
    tl.set(rootRef.current, { autoAlpha: 1 })
    tl.fromTo(
      bgRef.current,
      { y: '-100%' },
      { y: '0%', duration: 0.4, ease: 'power2.inOut' },
      0
    )
    tl.fromTo(
      darkenRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.4, ease: 'power2.inOut' },
      0
    )
    tl.fromTo(
      shapes,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.4,
        stagger: 0.1,
        ease: 'power2.inOut',
      },
      0
    )
  }, [])

  const playExitTransition = useCallback(() => {
    const shapes = shapesRef.current.querySelectorAll('.ani-shape')
    const tl = gsap.timeline() // ({ onStart: () => setBadgeMounted(true) })
    tl.to(
      bgRef.current,
      { y: '100%', duration: 0.4, delay: 0.4, ease: 'power2.inOut' },
      0
    )
    tl.to(
      darkenRef.current,
      { opacity: 0, duration: 0.4, delay: 0.5, ease: 'power2.inOut' },
      0
    )
    tl.to(
      shapes,
      {
        scale: 0,
        opacity: 0,
        duration: 0.4,
        stagger: 0.1,
        ease: 'power2.inOut',
      },
      0
    )
    tl.set(rootRef.current, { autoAlpha: 0 })
  }, [])

  useEffect(() => {
    switch (transition) {
      case 'in':
        playEnterTransition()
        break
      case 'out':
        playExitTransition()
        break
      default:
        break
    }
  }, [transition, playEnterTransition, playExitTransition])

  return (
    <div className={styles.PageTransition} ref={rootRef}>
      {DEBUG && (
        <div className={styles.debug}>State: {transition || 'ready'}</div>
      )}
      <div className={styles.darken} ref={darkenRef} />
      <div className={styles.bg} ref={bgRef} />
      <div className={styles.shapes} ref={shapesRef}>
        <div className={classnames(styles.shape, 'ani-shape')}>
          <Shape01 className={styles.svg} />
        </div>
        <div className={classnames(styles.shape, 'ani-shape')}>
          <Shape02 className={styles.svg} />
        </div>
        <div className={classnames(styles.shape, 'ani-shape')}>
          <Shape03 className={styles.svg} />
        </div>
        <div className={classnames(styles.shape, 'ani-shape')}>
          <Shape04 className={styles.svg} />
        </div>
      </div>
    </div>
  )
}

export { PageTransition }
