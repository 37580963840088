// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var PageTransition = "N_cj";
export var debug = "N_ck";
export var darken = "N_cl";
export var bg = "N_M";
export var shapes = "N_cm";
export var shape = "N_cn";
export var svg = "N_cp";
export var aniSpin = "N_l";