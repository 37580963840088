import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Btn } from '@/components/Btn'
import { RadioInput } from '@/components/RadioInput'
import { checkValidEmail } from '@/utils/checkValidEmail'
import { getBtnColors } from '@/utils/getBtnColors'
import * as styles from './MailchimpForm.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const MailchimpForm = ({ submitLabel, url, fields, success }) => {
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState([])

  const handleSubmit = (event, subscribe) => {
    if (event) {
      event.preventDefault()

      const errorMsgs = []

      fields.forEach((field) => {
        if (
          (field.isRequired && field.type === 'email' && !inputs[field.name]) ||
          (field.isRequired && field.type === 'text' && !inputs[field.name]) ||
          (field.isRequired && field.type === 'radio' && !inputs[field.name])
        ) {
          errorMsgs.push('Please fill out all required fields.')
        } else if (
          field.isRequired &&
          field.type === 'checkbox' &&
          !inputs[field.name]
        ) {
          errorMsgs.push('Checkbox is required.')
        } else if (
          field.type === 'email' &&
          !checkValidEmail(inputs[field.name])
        ) {
          errorMsgs.push('Your email address is invalid.')
        }
      })

      if (!errorMsgs.length) {
        subscribe(inputs)
        fireTrackingEvent()
      } else {
        setErrors(errorMsgs)
      }
    }
  }

  const fireTrackingEvent = () => {
    const analyticsData = {
      eventCategory: 'Register',
      eventAction: 'Clicked submit in Popup',
    }
    if (IS_BROWSER && window.gtag) {
      window.gtag('event', 'click', analyticsData)
    } else {
      console.log(analyticsData, 'click')
      console.warn('gtag events only work in production')
    }
  }

  const handleInputChange = (event) => {
    event.persist()
    setErrors([])

    let value
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else {
      value = event.target.value
    }

    setInputs((i) => ({
      ...i,
      [event.target.name]: value,
    }))
  }

  const removeDuplicates = (array) => {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  return (
    <div>
      {url && (
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            return (
              <div>
                <div>
                  {status === 'sending' && (
                    <div className={styles.message}>Sending...</div>
                  )}
                  {status === 'error' && (
                    <div className={styles.message}>
                      {typeof message === 'string' ? (
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                      ) : (
                        'Error submitting, please try again'
                      )}
                    </div>
                  )}
                  {status === 'success' && <div>{success}</div>}
                  {removeDuplicates(errors)
                    .reverse()
                    .map((error, index) => {
                      return (
                        <div
                          key={`${index}-error-${error}`}
                          className={styles.message}
                        >
                          {error}
                        </div>
                      )
                    })}
                </div>
                {status !== 'success' && (
                  <div>
                    {fields.map((field) => {
                      if (
                        field.type === 'text' ||
                        field.type === 'email' ||
                        field.type === 'tel'
                      ) {
                        return (
                          <div key={field.id} className={styles.field}>
                            <label htmlFor={field.id} className={styles.label}>
                              {field.label}
                            </label>
                            <input
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              placeholder={field.placeholder}
                              onChange={handleInputChange}
                              required={field.isRequired}
                            />
                          </div>
                        )
                      }
                      if (field.type === 'radio') {
                        return (
                          <div key={field.id} className={styles.field}>
                            <RadioInput
                              {...field}
                              value={inputs[field.name]}
                              onChange={handleInputChange}
                            />
                          </div>
                        )
                      }
                      return undefined
                    })}
                    <div className={styles.footer}>
                      <Btn
                        type="submit"
                        onClick={(event) => {
                          handleSubmit(event, subscribe)
                        }}
                        theme={getBtnColors('green')}
                      >
                        {submitLabel}
                      </Btn>
                    </div>
                  </div>
                )}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

MailchimpForm.defaultProps = {
  submitLabel: 'Submit',
  fields: [],
  onSuccess: () => {},
  successText: 'Thank you',
}

MailchimpForm.propTypes = {
  submitLabel: PropTypes.string,
  url: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  success: PropTypes.node,
}

export { MailchimpForm }
